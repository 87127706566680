var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-story"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"main-content"},[_c('Waterfall',{ref:"waterfall",attrs:{"list":_vm.mylist,"gutter":20,"width":284,"breakpoints":{
            1200: {
              //当屏幕宽度小于等于1200
              rowPerView: 4,
            },
            800: {
              //当屏幕宽度小于等于800
              rowPerView: 3,
            },
            500: {
              //当屏幕宽度小于等于500
              rowPerView: 2,
            },
          },"animationEffect":_vm.effect,"animationDuration":(_vm.duration + "s"),"animationDelay":(_vm.delay + "s"),"backgroundColor":"rgb(#fff, #fff,#fff)"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"cover",style:(_vm.initCardStyle(props)),on:{"click":function($event){return _vm.handleClick(props.data)}}},[_c('img',{attrs:{"src":props.data.pic.picUrl,"alt":""},on:{"load":_vm.$refs.waterfall.refresh}})]),_c('div',{staticClass:"name"},[_c('p',[_vm._v(_vm._s(props.data.ctstr))]),_c('p',[_vm._v(_vm._s(props.data.c))]),_c('p',[_vm._v(_vm._s(props.data.typeStr))])])])]}}])}),_vm._m(1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/images/banner2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-more"},[_c('p',[_vm._v("没有更多了")])])}]

export { render, staticRenderFns }