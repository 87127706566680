<!--
 * @Author: your name
 * @Date: 2020-06-01 10:03:34
 * @LastEditTime: 2020-09-17 15:43:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\views\customerStory\CustomerStory.vue
-->
<template>
  <div class="customer-story">
    <!--banner图-->
    <div class="banner">
      <img src="@/assets/images/banner2.png" alt />
    </div>
    <!--列表主题-->
    <div class="main">
      <div class="main-container">
        <div class="main-content">
          <Waterfall
            ref="waterfall"
            :list="mylist"
            :gutter="20"
            :width="284"
            :breakpoints="{
              1200: {
                //当屏幕宽度小于等于1200
                rowPerView: 4,
              },
              800: {
                //当屏幕宽度小于等于800
                rowPerView: 3,
              },
              500: {
                //当屏幕宽度小于等于500
                rowPerView: 2,
              },
            }"
            :animationEffect="effect"
            :animationDuration="`${duration}s`"
            :animationDelay="`${delay}s`"
            backgroundColor="rgb(#fff, #fff,#fff)"
          >
            <template slot="item" slot-scope="props">
              <div class="card">
                <div class="cover" :style="initCardStyle(props)" @click="handleClick(props.data)">
                  <img :src="props.data.pic.picUrl" alt @load="$refs.waterfall.refresh" />
                </div>

                <div class="name">
                  <p>{{ props.data.ctstr }}</p>
                  <p>{{ props.data.c }}</p>
                  <p>{{ props.data.typeStr }}</p>
                </div>
                <!-- <div class="menus">
                  <p data-title="编辑" @click="handleEdit(props.data)" />
                  <p data-title="删除" @click="handleDelete(props.data)" />
                </div>-->
              </div>
            </template>
          </Waterfall>
          <div class="add-more">
            <p>没有更多了</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { list } from "@/service/api.js";
// import Waterfall from "vue-waterfall-easy";
import Waterfall from "vue-waterfall-plugin";

export default {
  name: "CustomerStory",
  components: { Waterfall },
  data() {
    return {
      mylist: [],
      page: 1,
      size: 8,
      effect: "animate__backInUp",
      duration: 1.5,
      delay: 0.3,
      isSetInitStyle: true,
      isOpen: false,
      slideWidth: 200,
      boxWidth: "auto"
    };
  },
  watch: {
    "$store.state.height": function(item) {
      if (item < 300) {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      this.page++;
      this.getList();
    },
    getList() {
      list({
        start: (this.page - 1) * this.size,
        size: this.size
      }).then(res => {
        console.log(res);
        res.attachment.datas.map(res => {
          res.src = res.pic.picUrl;
        });
        this.mylist.push(...res.attachment.datas);
      });
    },

    // 初始化卡片样式
    initCardStyle(props) {
      if (this.isSetInitStyle) {
        return {
          width: `${props.data.itemWidth - 20}px`,
          height: `${((props.data.itemWidth - 20) / props.data.width) *
            props.data.height}px`,
          backgroundColor: props.data.blankColor
        };
      }
    },
    /**
     * 图片点击
     */
    handleClick() {
      // this.$message.info(JSON.stringify(item));
      // console.log(item)
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    }
  },
  mounted() {
    this.isSetInitStyle = window.localStorage.getItem("isSetInitStyle")
      ? JSON.parse(window.localStorage.getItem("isSetInitStyle"))
      : false;
    this.getList();
    window.addEventListener("scroll", this.windowScroll);
  }
};
</script>

<style lang="less" scoped>
.customer-story {
  width: 100%;
}
.banner {
  width: 85%;
  height: 600px;
  overflow: hidden;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
* {
  margin: 0;
  padding: 0;
}
.container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  .slider-wrapper {
    height: 100%;
    background-color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    p {
      font-size: 30px;
      color: #fff;
    }
    span {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      right: -40px;
      top: 0;
      z-index: 999999;
      background-color: red;
    }
  }
}
.main-container {
  display: flex;
  .main-content {
    flex: 1;
    .card {
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      transition: 0.2s;
      top: 0;
      &:hover {
        top: -3px;
      }
      .cover {
        margin: 10px 10px 0 10px;
        img {
          display: block;
          width: 100%;
        }
      }
      .name {
        background: #fff;
        color: #666;
        font-weight: 600;
        padding: 10px 20px;
        font-size: 14px;
        p:first-child {
          font-size: 14px;
        }
        p:nth-child(2) {
          font-size: 18px;
          color: #000;
          line-height: 36px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          font-weight: 200;
        }
        p:last-child {
          font-size: 14px;
          line-height: 26px;
        }
      }
      .menus {
        padding: 10px;
        border-top: 1px solid #e7e7e7;
        text-align: right;
        p {
          position: relative;
          display: inline-block;
          padding: 4px 10px;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
          user-select: none;
          color: white;
          font-size: 12px;
          margin-left: 10px;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(135deg, #6e8efb, #a777e3);
            border-radius: 4px;
            transition: box-shadow 0.5s ease, transform 0.2s ease;
            will-change: transform;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            transform: translateY(var(--ty, 0)) rotateX(var(--rx, 0))
              rotateY(var(--ry, 0)) translateZ(var(--tz, -12px));
          }
          &:hover::before {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          }
          &::after {
            position: relative;
            display: inline-block;
            content: attr(data-title);
            transition: transform 0.2s ease;
            font-weight: bold;
            letter-spacing: 0.01em;
            will-change: transform;
            transform: translateY(var(--ty, 0)) rotateX(var(--rx, 0))
              rotateY(var(--ry, 0));
          }
        }
      }
    }
  }
  .slide-menu {
    flex: 0 0 200px;
    width: 200px;
    .operations {
      position: fixed;
      right: 0;
      width: 200px;
      top: 0;
      bottom: 0;
      background-color: #333;
      box-sizing: border-box;
      padding: 20px;
      .item {
        margin-bottom: 20px;
        .title {
          padding-bottom: 10px;
          text-align: left;
          color: #fff;
        }
      }
      .github {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
      }
    }
  }
}
.add-more {
  // .button {
  //   display: block;
  //   width: 180px;
  //   height: 40px;
  //   border-radius: 3px;
  //   background: #f2f2f2;
  //   border: none;
  //   outline: none;
  //   color: #666;
  //   font-weight: 900;
  //   margin: 0 auto;
  //   cursor: pointer;
  // }
  width: 100%;
  height: 120px;
  background: #f5f5f5;
  color: #666;
  text-align: center;
  line-height: 120px;
}
.waterfull-grid {
  width: 88%;
  background: #f5f5f5;
}
.main {
  width: 85%;
  background: #f5f5f5;
  margin: 0 auto;
  margin-bottom: 200px;
}
</style>
